import React from 'react';
import { useSelector } from 'react-redux';
import { graphql } from 'gatsby';
import { HomeWrapper } from '../elements';
import { Container, FeatureImage, Seo } from '../components';

const Home = ({ location, data }) => {
  const locale = useSelector((state) => state.localeString);
  const isDark = useSelector((state) => state.isDark);
  return (
    <Container pathname={location.pathname} isDark={isDark} locale={locale}>
      <Seo />

      <HomeWrapper isDark={isDark}>
        <div className={'top'}>
          <FeatureImage />
        </div>
        <br />
        <div className={'bio'}>
          <h2>Gabrielle Devine</h2>
          <br />
          <p>
            Artist based in Glasgow. Born in year 2000. Currently a student at
            the Glasgow School of Art.
          </p>
          <br />
          <p>
            Often humorous, my work explores the various ways and places in
            which women present themselves. On the digital screen or physical
            places- images from tv or of friends. Using digital screen is an
            important factor in my work.
          </p>
          <br />
          <p>
            Ranging from works with subdued, muted palettes, to overly saturated
            and vibrant tones, each work depicts a light-hearted and sensitive
            approach to the women I paint. Figures range from floating in
            ambiguous, often digital spaces to being in real-life domestic
            environments.
          </p>
          <br />
          <p>
            My work often deals with themes of body adornment, being used as a
            tool for self-expression, specifically enabling people to perform
            gender and play with image. However, with this also carries
            vulnerability through the societal repercussions of women who, for
            example are heavily tattooed or wear a lot of makeup. Extreme body
            adornment can be considered ‘trashy’ by normative societal values so
            I challenge these views by depicting them in my work. I aim for my
            work to provide a light hearted approach to challenging the ways in
            which women are depicted and judged, mostly on the digital screen.
          </p>
        </div>
      </HomeWrapper>
    </Container>
  );
};
export default Home;

export const homeTemplateQuery = graphql`
  query HomeTemplateQuery($locale: String!) {
    allMdx(
      filter: {
        frontmatter: { motives: { eq: "home" }, locale: { eq: $locale } }
      }
    ) {
      edges {
        node {
          frontmatter {
            slug
            title
          }
        }
      }
    }
  }
`;
